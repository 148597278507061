/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ImageUrl } from "../../../utils/constants";
import {
  convertDateInTimezone,
  // getChallengeTimeToShow,
  getTimeToShow,
  // getChallengeTimeShow,
  convertMonthFromDate,
  getChallengeTimeShowNew,
  getChallengeTimeToShowNew,
} from "../../../utils/methods";
import momentTZ from "moment-timezone";
import isNull from "lodash/isNull";
import { withTranslation } from "react-i18next";
import { joinOrLeaveChallenge } from "../../../redux/actions/challengeActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  ChallengeCard,
  ImageCard,
  BottomCard,
  ChallangeTypeCard,
} from "./styles";
import LazyImage from "../../common/LazyImage/LazyImage";
import Waiting from "../../Waiting";


class NewSingleChallenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      times: [],
    };
  }

  componentDidMount() {
    const { challenge } = this.props;
    this.setState({
      time: challenge && getChallengeTimeShowNew(challenge, this.props),
    });
    this.interval = setInterval(() => {
      this.setState({
        times: getTimeToShow(challenge, "challenge"),
      });
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    const { challenge } = this.props;
    if (prevProps.challenge.id !== challenge.id) {
      this.setState({
        time: challenge && getChallengeTimeToShowNew(challenge, this.props),
      });
    }
    if (prevProps.challenge !== this.props.challenge) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.setState({
          times: getTimeToShow(this.props.challenge, "challenge"),
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fallBack = (e) => {
    e.target.src = "/images/fallback.png";
  };

  challengeNavigation = (challengeId, isDummy) => {
    const { history, toggleDummyChallenges, adminChallenge } = this.props;
    if (isDummy) toggleDummyChallenges(true);
    if(adminChallenge){
      history.push(`/company/challenges/${challengeId}${isDummy ? "?ACME=true" : ""}`);
    }else{
      history.push(`/challenges/${challengeId}${isDummy ? "?ACME=true" : ""}`);
    }
  };

  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined,
    };
    joinOrLeaveChallenge(data);
  };

  render() {
    const { time } = this.state;
    const { challenge,  t , showAll, onHover, onEnterMouse ,onLeaveMouse, } = this.props;
    if (isNull(challenge)) {
      return <Waiting />;
    }
    const challengeStartDate = convertDateInTimezone(challenge.start_date)._d;
    const currentDate = momentTZ();

    let challengeStatus = null;
    if (currentDate.isSameOrAfter(challengeStartDate)) {
      challengeStatus = "Active";
    } else if (currentDate.isBefore(challengeStartDate)) {
      challengeStatus = "Upcoming";
    }
    let challengeId = challenge.id;
    const DaysLeft = time.map((step) => step);
    // const marginRight = margin + 1;
    const lang = localStorage.getItem("lang");

    return (
      <ChallengeCard
        // margin={marginRight % 2 === 0 ? "0px" : "25px"}
        onClick={() =>
          this.challengeNavigation(challengeId, challenge.is_dummy_challenge)
        }
        width={showAll ? "399px" : "399px"}
        onMouseEnter={() => onEnterMouse(challengeId)} onMouseLeave={() => onLeaveMouse()}
      >
        <ChallangeTypeCard>{t(challengeStatus)}</ChallangeTypeCard>
        <ImageCard>
          <LazyImage src={`${ImageUrl}/${challenge.image}`} alt="challenge" />
          { onHover === challengeId &&
              <div className="educateImageBackground" >
                <div className="getStarted" onClick={() => this.challengeNavigation(challenge)}>{t("View Details")}</div> 
              </div>
          }
        </ImageCard>
        <BottomCard style={{height:'84px'}}>
          <div className="challenge-name">
            <span className="name">{t(challenge.title)}</span>
            <div className="icon">
              <LazyImage
                div={"icon"}
                src={`${ImageUrl}/${challenge.event_icon}`}
                alt="event-type"
              />
            </div>
          </div>
          <div className="date">
            { (lang !== "fr") ? (convertMonthFromDate(moment(convertDateInTimezone(challenge.start_date)._d).format(
              "DD MMM, YYYY"
            ), this.props)) : (convertMonthFromDate(moment(convertDateInTimezone(challenge.start_date)._d).format(
              "DD MMM YYYY"
            ), this.props))}
            -{" "}
            { (lang !== "fr") ? (convertMonthFromDate(moment(convertDateInTimezone(challenge.end_date)._d).format(
              "DD MMM, YYYY"
            ), this.props)) : (convertMonthFromDate(moment(convertDateInTimezone(challenge.end_date)._d).format(
              "DD MMM YYYY"
            ), this.props))}
            <div style={{margin:'0px 5px'}}>{" | "}</div>
            <div className="days">
              
              {time.map(
                (step, index) => step + (index === time.length - 1 ? "" : ", ")
              )}
              {DaysLeft == "Starts Today" || DaysLeft == "Last Day"
                ? null
                : (convertDateInTimezone(challenge.start_date)._d <=
                  momentTZ()._d)
                  ? t(" to End")
                  : t(" to start")}
            </div>
          </div>
          {/* <div className="participant-card">
            {challengeAttendees && challengeAttendees.length > 0 && (
              <div className="count">{challengeAttendees.length}+</div>
            )}
            <div className="participant">
              {challengeAttendees && challengeAttendees.length > 0
                ? t("Participants")
                : t("No Participant")}
            </div>
          </div> */}
        </BottomCard>
      </ChallengeCard>
    );
  }
}

NewSingleChallenge.propTypes = {
  onHover: PropTypes.number,
  onEnterMouse: PropTypes.func.isRequired,
  onLeaveMouse: PropTypes.func.isRequired,
  challenge: PropTypes.object,
  history: PropTypes.object,
  toggleDummyChallenges: PropTypes.func,
  t: PropTypes.func,
  margin: PropTypes.number,
  joinOrLeaveChallenge: PropTypes.func,
  adminChallenge: PropTypes.number,
  challengeAttendees: PropTypes.array,
  showAll : PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
});

const mapStateToProps = (state) => ({
  challengeAttendees: state.challenges.challengeAttendees,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewSingleChallenge))
);
